import React, { useEffect, useState } from "react";
import "../Styles/Footer.css";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(
    window.document.documentElement.clientWidth <= 991
  );

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const resizeHandler = () => {
    setIsMobile(window.document.documentElement.clientWidth <= 768);
  };

  // current year
  const currentYear = new Date().getFullYear();
  return (
    <div className="container" id="footer">
      <div className="row">
        {isMobile ? (
          <>
            <div className="col-md-6" id="social-icons">
              <a href="https://twitter.com/SwiftyGlobal">
                <img
                  src="../images/twitter.svg"
                  alt="Swifty Global Twitter Account"
                />
              </a>
              <a href="https://www.linkedin.com/company/swifty-global">
                <img
                  src="../images/linkedin.svg"
                  alt="Swifty Global LinkedIn Account"
                />
              </a>
              <a href="https://t.me/swiftyglobal">
                <img
                  src="../images/telegram.svg"
                  alt="Swifty Global Telegram Account"
                />
              </a>
              <a href="https://www.instagram.com/swiftyglobal/">
                <img
                  src="../images/instagram.svg"
                  alt="Swifty Global Instagram Account"
                />
              </a>
            </div>
            <div className="col-md-6" id="upper-text">
              {/* <a href="https://www.swiftypredictions.co.uk/">
                Swifty Predictions Website
              </a> */}
              {/* <a href="https://www.swiftysports.com/">
                {" "}
                Swifty Sports Website
              </a> */}
            </div>

            <span className="swifty-footer-divider"></span>

            <div className="col-md-6" id="lower-text">
              <a href="/termandconditions">Terms and Conditions |</a>
              <a href="/privacy"> Privacy policy |</a>
              <a href="/cookiepolicy"> Cookie Policy</a>
            </div>
            <div className="col-md-6" id="lower-text-right">
              <p>© Swifty Global. All rights reserved, 2022</p>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-6" id="upper-text">
              {/* <a href="https://www.swiftypredictions.co.uk/" target="_blank">
                Swifty Predictions Website
              </a> */}
              {/* <a href="https://www.swiftysports.com/">
                {" "}
                Swifty Sports Website
              </a> */}
            </div>
            <div className="col-md-6" id="social-icons">
              <a href="https://twitter.com/SwiftyGlobal">
                <img
                  src="../images/twitter.svg"
                  alt="Swifty Global Twitter Account"
                />
              </a>
              <a href="https://www.linkedin.com/company/swifty-global">
                <img
                  src="../images/linkedin.svg"
                  alt="Swifty Global LinkedIn Account"
                />
              </a>
              <a href="https://t.me/swiftyglobal">
                <img
                  src="../images/telegram.svg"
                  alt="Swifty Global Telegram Account"
                />
              </a>
              <a href="https://www.instagram.com/swiftyglobal/">
                <img
                  src="../images/instagram.svg"
                  alt="Swifty Global Instagram Account"
                />
              </a>
            </div>

            <span className="swifty-footer-divider"></span>

            <div className="col-md-6" id="lower-text">
              <a href="/termsandconditions">Terms and Conditions |</a>
              <a href="/privacy"> Privacy policy |</a>
              <a href="/cookiepolicy"> Cookie Policy</a>
            </div>
            <div className="col-md-6" id="lower-text-right">
              <p>© Swifty Global. All rights reserved, {currentYear}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Footer;
