import React from 'react'
import { Helmet } from 'react-helmet'
import '../../pages/Authentication/Login.css'
import { Link } from "react-router-dom"

const ForgotPassword = () => {
  return (
    <><Helmet>
      <title>Login | CMS | Swifty Global</title>
    </Helmet><section className="gradient-custom">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="card pt-0 bg-dark text-dark">
                <div className="card-body bg-light text-center">

                  <div className="mb-md-5 mt-md-4 pb-3">
                    <img src="../images/sp-logo-dark.png" className='swifty-logo' alt="Swifty Logo" />
                    <h4 className="welcome-text mb-3 mt-5">Reset Password</h4>
                    <div className="form-outline form-dark">
                    <div className="alert alert-info" role="alert">
                      Enter your Email and instructions will be sent to you!
                    </div>
                      <label className="form-label justify-content-left" htmlFor="typeEmailX">Email</label>
                      <input type="email" id="typeEmailX" className="form-control form-control-md" placeholder='Enter Email'/>
                    </div>
                  </div>
                        <div className="text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light px-5"
                            type="submit"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                </div>
              </div>
          </div>
          <div className="copyright mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link to="/login" className="text-primary">
                    Sign In Here
                  </Link>{" "}
                </p>
                <p>
                  Copyright © {new Date().getFullYear()} {" "}
                  <span className="d-none d-sm-inline-block">
                    Swifty Global
                  </span>
                </p>
              </div>
        </div>
      </section></>
  )
}

export default ForgotPassword