import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieComponent = () => {
  return (
    <div
      style={{
        background: "#0058BE",
        position: "fixed",
        width: "100%",
        height: "fit-content",
        left: 0,
        bottom: 0,
      }}
    >
      <CookieConsent
        location="bottom"
        buttonStyle={{
          border: "2px solid white",
          borderRadius: "5px",
          width: "120px",
          boxSizing: "border-box",
          background: "#0058BE",
          color: "white",
          fontSize: "14px",
          fontFamily: "Jeko-Bold",
          marginRight: "calc(15px + .75rem)",
          marginTop: "-1rem",
        }}
        buttonClasses={"rcc-confirm-button"}
        expires={365}
        buttonText="Accept"
        containerClasses={"CookieConsent container"}
        style={{ background: "#0058BE", position: "unset" }}
      >
        <p className="cookie-text">
          This site is governed by the terms and conditions of Swifty Global’s
          Data Privacy and Cookie Policy. Clicking the Accept button, or
          continuing to use the site, means you agree with our
          <a href="/cookiepolicy" className="data-cookie">
            Data Privacy and Cookie policy.
          </a>
        </p>
      </CookieConsent>
    </div>
  );
};

export default CookieComponent;
