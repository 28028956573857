import React, { Component } from "react";
import "../Styles/Home.css";
import Slider from "react-slick";

export default class HomeSlider extends Component {
  render() {
    const settings = {
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      slidesToShow: 1,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      slidesToScroll: 1,
    };
    return (
      <div className="container-xxl home-slider">
        <div className="row">
          <Slider {...settings}>
            <div className="row" id="first-row">
              <img src="../images/Banner_Predictions_background.png" alt="" />
              <div className="col-md-6 banner-content">
                <img
                  src="../images/Banner_Predictions.png"
                  alt="Swifty Banner"
                  className="banner-predictions"
                />
                <img
                  src="../images/text-predictions.svg"
                  alt="Predictions"
                  className="predictions-text"
                />
                <span className="banner-text">
                  Game, bet, match. <br />
                  Betting in swipe - sports betting made easy.
                </span>
              </div>
            </div>
            <div className="row" id="first-row">
              <img src="../images/Banner_Sportsbook.png" alt="" />
              <div className="col-md-6 banner-content">
                <img
                  src="../images/Banner_Sportsbook_text.png"
                  alt="Swifty Banner"
                  className="banner-predictions"
                />
                <img
                  src="../images/sports_casino.png"
                  alt="Sports & Casino"
                  className="predictions-text"
                />
                <span className="banner-text">
                  Bet on all your favourite sports including horse racing,
                  football, rugby and more.
                </span>
              </div>
            </div>
            {/* <div className="row" id="second-row">
              <img src="../images/swifty-wallet-hero.png" alt="" />
              <div className="col-md-6 banner-content">
                <img
                  src="../images/Banner_Wallet_text.png"
                  alt="Swifty Banner"
                  className="banner-predictions"
                />
                <img
                  src="../images/Wallet-banner.svg"
                  alt="Wallet"
                  className="predictions-text"
                />
                <span className="banner-text">
                  Control every aspect of your finances with <br />
                  Swifty Wallet, the app that does it all.
                </span>
              </div>
            </div> */}
          </Slider>
        </div>
      </div>
    );
  }
}
