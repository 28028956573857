import React, { useEffect, useState } from "react";
import NavbarComponent from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import "../components/Styles/BlogPage.css";
import LatestNews from "../components/LatestNews/LatestNews";
import { Helmet } from "react-helmet";
import Drcr from "../components/Drcr/Drcr";
import axios from "axios";
import { useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import Moment from "moment";
import CookieComponent from "../components/CookieComponent/CookieComponent";
import ReactDom from "react-dom";

const BlogPage = () => {
  const [types, setTypes] = useState("all");
  const [cards, setCards] = useState([]);
  const [article, setArticle] = useState(null);

  let { article: articleSlug } = useParams();

  const getDocs = (limit) => {
    axios
      .post(
        "https://18z0qwmdu8.execute-api.eu-west-1.amazonaws.com/main/v1/data",
        {
          collection: "articles",
          sort: { date: -1 },
          limit: limit ? limit : 3,
        }
      )
      .then((res) => {
        setCards(res.data.documents);
      });
  };

  const getArticle = () => {
    axios
      .post(
        "https://18z0qwmdu8.execute-api.eu-west-1.amazonaws.com/main/v1/data",
        {
          collection: "articles",
          filter: { slug: articleSlug },
          limit: 1,
        }
      )
      .then((res) => {
        setArticle(res.data.documents[0]);
      });
  };
  useEffect(() => {
    getDocs();
    getArticle();
  }, [articleSlug]);

  return (
    <div className="container-xxl blog-page background-image">
      <Drcr />
      <div className="blog-page">
        <div className="container">
          <NavbarComponent />
          {article ? (
            <div className="articleContent">
              <Helmet>
                <title property="title">{article.title} | Swifty Global</title>
                <meta
                  name="description"
                  content={`${article.short_description}`}
                  data-react-helmet="true"
                />
                <meta
                  property="og:title"
                  content={`${article.title} | Swifty Global`}
                  data-react-helmet="true"
                />
                <meta
                  property="og:description"
                  content={`${article.short_description}`}
                  data-react-helmet="true"
                />
                <meta
                  property="og:type"
                  content="website"
                  data-react-helmet="true"
                />
                <meta
                  property="og:url"
                  content={`https://www.swifty.global/article/${article.slug}`}
                  data-react-helmet="true"
                />
              </Helmet>
              <div className="article-title">{article.title}</div>
              <div className="article-date">
                {Moment(article.date).format("dddd, DD MMMM YYYY")}
              </div>
              {(article.video.length <= 0) & (article.image.length > 0) ? (
                <div className="article-image">
                  <img
                    src={article.image}
                    className="card-img-top"
                    alt={article.title}
                  />
                </div>
              ) : (
                ""
              )}
              {article.video.length > 0 && (
                <div className="article-video">
                  <iframe
                    src={`https://www.youtube.com/embed/${article.video}`}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="Video Player"
                    className="video"
                  />
                </div>
              )}
              <div className="article-description">
                {HTMLReactParser(article.description)}
              </div>
            </div>
          ) : (
            <>
              {article !== null ? (
                <div className="articleContent">
                  <div className="article-title">
                    This article could not be found, if you entered the url
                    please check it again.
                  </div>
                  <div className="article-title">
                    Our latest articles can be found below or you can go back to
                    our latest news page by &nbsp;
                    <a href="/whatsnew">Clicking Here</a>
                  </div>
                </div>
              ) : null}
            </>
          )}

          <div className="latest-news-blog-page">
            <LatestNews page={"article"} types={types} cards={cards} />
          </div>
        </div>
        <Footer />
        <CookieComponent />
      </div>
    </div>
  );
};

export default BlogPage;
