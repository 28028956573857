import React from "react";
import "../Drcr/Drcr.css";

const Drcr = () => {
  return (
    <div className="container-xxl" id="container-drcr">
      <div className="drcr">
        <a target="_blank" href="https://www.otcmarkets.com/stock/DRCR/overview">
          Swifty Global is now trading on the OTC Market under ticker DRCR
        </a>
      </div>
    </div>
  );
};

export default Drcr;
