import React, { Component } from "react";
import Slider from "react-slick";
import "../Styles/Home.css";

export default class HighlightsMobile extends Component {
  render() {
    const settings = {
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 481,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          <div className="col-md-3" id="highlights-mobile">
            <div className="highlights-img">
              <img src="../images/q1.svg" alt="Q1" />
            </div>
            <span>
              <ul className="highlights-list">
                <li>UK Gambling License</li>
                <li>Launch Swifty Wallet</li>
              </ul>
            </span>
          </div>
          <div className="col-md-3" id="highlights-mobile">
            <div className="highlights-img">
              <img src="../images/q2.svg" alt="Q2" />
            </div>
            <span>
              <ul className="highlights-list">
                <li>Apply Crypto license</li>
                <li>Launch Swifty Predictions</li>
                <li>Launch Swifty Sportsbook</li>
              </ul>
            </span>
          </div>

          <div className="col-md-3" id="highlights-mobile">
            <div className="highlights-img">
              <img src="../images/q3.svg" alt="Q3" />
            </div>
            <span>
              <ul className="highlights-list">
                <li>Marketing Campaign</li>
                <li>Launch Swifty Casino</li>
                <li>Additional Gambling Licenses (TBC)</li>
              </ul>
            </span>

          </div>

          <div className="col-md-3" id="highlights-mobile">
            <div className="highlights-img">
              <img src="../images/q4.svg" alt="Q4" />
            </div>
            <span>
              <ul className="highlights-list">
                <li>Financial license for issuing accounts</li>
                <li>Phase 2 Swifty Wallet</li>
              </ul>
            </span>
          </div>
        </Slider>
      </div>
    );
  }
}
