import React, { useEffect } from "react";
import NavbarComponent from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Drcr from "../components/Drcr/Drcr";
import { Helmet } from "react-helmet";
import "../components/Styles/CookieTermsPrivacy.css";
import CookieComponent from "../components/CookieComponent/CookieComponent";

const Privacy = () => {
  useEffect(() => {
    (function (d, s, id) {
      var js,
        tjs = d.getElementsByTagName(s)[0];

      // if (d.getElementById(id)) return;

      js = d.createElement(s);
      js.id = id;

      js.src = "https://app.termly.io/embed-policy.min.js";

      tjs.parentNode.insertBefore(js, tjs);
    })(document, "script", "termly-jssdk");
  }, []);

  return (
    <div className="container-xxl">
      <Drcr />
      <div className="container">
        <NavbarComponent />
        <React.Fragment>
          <Helmet>
          <title>Privacy Policy | Swifty Global</title>
            <meta
            name="description"
            content="Review the measures Swifty Global takes to protect user privacy. Our policy provides transparency about data usage, storage, and your personal privacy rights."
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content="Privacy Policy | Swifty Global"
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content="Review the measures Swifty Global takes to protect user privacy. Our policy provides transparency about data usage, storage, and your personal privacy rights."
            data-react-helmet="true"
          />
            <meta
            property="og:type"
            content="website"
            data-react-helmet="true"
          />
          <meta
            property="og:url"
            content="https://www.swifty.global/privacy"
            data-react-helmet="true"
          />
          </Helmet>
          <div className="cookie-policy-container">
            <div className="center-container">
              <div
                name="termly-embed"
                data-id="e83e6456-2a0a-4141-926d-56e9b70dd263"
                data-type="iframe"
              ></div>
            </div>
          </div>
        </React.Fragment>
        <Footer />
        <CookieComponent />
      </div>
    </div>
  );
};

export default Privacy;
