import React, { useEffect, useState } from "react";
import NavbarComponent from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import "../components/Styles/WhatsNew.css";
import LatestNews from "../components/LatestNews/LatestNews";
import { Helmet } from "react-helmet";
import Drcr from "../components/Drcr/Drcr";
import { Link } from "react-router-dom";
import Button from "../components/Button/Button.component";
import { latestNewsTypes } from "../utils/globalUtilities";
import axios from "axios";
import CookieComponent from "../components/CookieComponent/CookieComponent";

const WhatsNew = () => {
  const [types, setTypes] = useState("all");
  const [isMobile, setIsMobile] = useState(
    window.document.documentElement.clientWidth <= 991
  );
  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const resizeHandler = () => {
    setIsMobile(window.document.documentElement.clientWidth <= 991);
  };

  const [cards, setCards] = useState([]);
  const [singleCard, setSingleCard] = useState([]);
  const handleButtonClick = (event) => {
    const type = event.target.dataset.type;
    setTypes(type);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [check, setCheck] = useState(true);
  const [skip, setSkip] = useState(0);

  const loadMore = () => {
    setIsLoading(true);
    getDocs(6);
  };
  const getDocs = (limit) => {
    axios
      .post(
        "https://18z0qwmdu8.execute-api.eu-west-1.amazonaws.com/main/v1/data",
        {
          collection: "articles",
          sort: { date: -1 },
          skip: skip ? skip : 0,
          limit: limit ? limit : 7,
        }
      )
      .then((res) => {
        if (check === true) {
          let resp = res.data.documents;
          setSingleCard(resp[0]); //first object
          resp = resp.slice(1);
          setCards(resp); //second object onwards
          setCheck(false);
          setSkip(skip + 7);
          setIsLoading(false);
        } else {
          setCards((oldArray) => [...oldArray, ...res.data.documents]);
          setSkip(skip + 6);
          setIsLoading(false);
        }
      });
  };
  useEffect(() => {
    getDocs();
  }, []);

  let loadingAnimation = (
    <div className="snippet" data-title=".dot-flashing">
      <div className="stage">
        <div className="dot-flashing"></div>
      </div>
    </div>
  );

  return (
    <div className="container-xxl whats-new">
      <div className="whats-new-hero">
        <Drcr />
        <div className="container">
          <Helmet>
          <title>Latest News and Updates | Swifty Global</title>
          <meta
            name="description"
            content="Explore the latest Swifty Global news, press releases, and important announcements relating to the company. Where we have come from and where we are going."
            data-react-helmet="true"
          />
          <meta
            name="keywords"
            content="Swifty Global News, Technology Updates, Latest Trading News, Swifty Global Announcements, Trading Platform Developments, Swifty Press Releases, Market Insights, Gambling Trends"
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content="Latest News and Updates | Swifty Global"
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content="Explore the latest Swifty Global news, press releases, and important announcements relating to the company. Where we have come from and where we are going."
            data-react-helmet="true"
          />
            <meta
            property="og:type"
            content="website"
            data-react-helmet="true"
          />
          <meta
            property="og:url"
            content="https://www.swifty.global/whatsnew"
            data-react-helmet="true"
          />
          </Helmet>
          <NavbarComponent />
          <div className="row">
            <div className="col-md-6">
              <span className="banner-title">
                <p>{singleCard.title}</p>
              </span>
              <span className="banner-txt">
                <p>{singleCard.short_description}</p>
              </span>
              {singleCard.title ? (
                <div className="read-button-gradient">
                  <Link to={`/article/${singleCard.slug}`}>
                    <button className="read-button">Read More</button>
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
          <div className="row">
            {isMobile ? (
              <></>
            ) : (
              <>
                <div className="col-md-3">
                  <span>
                    <p className="filter-title">Filter</p>
                    <div className="btn-group-vertical">
                      <span>
                        <Button
                          className="filter-buttons"
                          onclickHandler={handleButtonClick}
                          attributes={{
                            "data-type": latestNewsTypes[0],
                          }}
                        >
                          All Swifty
                        </Button>
                      </span>
                      <span>
                        <Button
                          className="filter-buttons"
                          onclickHandler={handleButtonClick}
                          attributes={{
                            "data-type": latestNewsTypes[1],
                          }}
                        >
                          Swifty Predictions
                        </Button>
                      </span>
                      <span>
                        <Button
                          className="filter-buttons"
                          onclickHandler={handleButtonClick}
                          attributes={{
                            "data-type": latestNewsTypes[3],
                          }}
                        >
                          Swifty SportsBook
                        </Button>
                      </span>
                    </div>
                  </span>
                </div>
              </>
            )}

            <div className="col-md-12 col-sm-12 col-lg-9 latest-news-wn-page">
              <LatestNews page={"whatsNew"} types={types} cards={cards} />

              {cards.length >= skip - 1 ? (
                <div className="load-more-ctr">
                  {!isLoading && (
                    <button className="load-more-btn" onClick={loadMore}>
                      Load More
                    </button>
                  )}
                  {isLoading && (
                    <button className="load-more-btn" disabled>
                      {loadingAnimation}
                    </button>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <Footer />
          <CookieComponent />
        </div>
      </div>
    </div>
  );
};

export default WhatsNew;
