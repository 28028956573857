import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import "./index.css";
import WhatsNew from "./pages/WhatsNew";
import Contact from "./pages/Contact";
import WhoWeAre from "./pages/WhoWeAre";
import Home from "./pages/Home";
import BlogPage from "./pages/BlogPage";
import { Helmet } from "react-helmet";
import Privacy from "./pages/Privacy";
import CookiePolicy from "./pages/CookiePolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import ScrollOnTop from "./components/ScrollOnTop/ScrollOnTop";
import Login from "./pages/Authentication/Login";
import ForgotPassword from "./pages/Authentication/ForgetPassword";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <ScrollOnTop />
    <Helmet>
      <meta charSet="utf-8" />
      <title>Swifty Global - Leading the Future of Gaming & Betting</title>
      <link rel="canonical" href="https://www.swifty.global" />
      <meta
        name="description"
        content="Swifty is a technology company focused on creating groundbreaking solutions in the gambling and betting sector"
        data-react-helmet="true"
      />
      <meta
        name="keywords"
        content="Online Casino, Online Sportsbook, Betting, Slots, Live Casino, In-Play Betting, Gambling Solutions, Swifty Sports"
        data-react-helmet="true"
      />
      <meta
        property="og:title"
        content="Swifty Global - Leading the Future of Gaming & Betting"
        data-react-helmet="true"
      />
      <meta
        property="og:description"
        content="Swifty is a technology company focused on creating groundbreaking solutions in the Financial and Sports Betting sectors"
        data-react-helmet="true"
      />
      <meta
        property="og:type"
        content="website"
        data-react-helmet="true"
      />
      <meta
        property="og:url"
        content="https://www.swifty.global"
        data-react-helmet="true"
      />
      <meta
        property="og:image"
        content="https://main.d3a1a0hl7kliu.amplifyapp.com/static/300e97b71b98f41eb8067e168d2048f9/logo_square_b79b73b887.png"
        data-react-helmet="true"
      />
    </Helmet>
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/home" element={<Home />} /> */}
      <Route path="/whoweare" element={<WhoWeAre />} />
      <Route path="/whatsnew" element={<WhatsNew />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/article/:article" element={<BlogPage />} />
      <Route path="/termsandconditions" element={<TermsAndConditions />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/cookiepolicy" element={<CookiePolicy />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="*" element={<Home />} /> //page not found
    </Routes>
  </Router>
);
