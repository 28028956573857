import React from "react";
import Card from "../Card/Card";
import "../Card/Card.css";

const LatestNewsMobileFilter = ({ page, types, cards }) => {
  return (
    <div>
      <div className="news-card-text">
        {page == "article" ? <p>Recommended News</p> : <p>Latest News</p>}
        {/*<img*/}
        {/*  src={filterIcon}*/}
        {/*  alt="Menu Filter Icon"*/}
        {/*  className="latest-filter-icon"*/}
        {/*/>*/}
      </div>
      {cards.map((card, index) => {
        return types === "all" || card.tags.includes(types) ? (
          <div key={index} className="col-md-12 newsRow">
            <Card
              link={`/article/${card.slug}`}
              pageType={page}
              image={card.image}
              title={card.title}
              date={card.date}
              text={card.text}
              short_description={card.short_description}
              media={card.media}
            />
          </div>
        ) : (
          ""
        );
      })}
    </div>
  );
};

export default LatestNewsMobileFilter;
