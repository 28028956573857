import React, { useEffect, useState } from "react";
import "../Styles/LatestNews.css";
import LatestNewsMobile from "./LatestNewsMobile";
import Card from "../Card/Card";
import LatestNewsMobileFilter from "./LatestNewsMobileFilter";
import { Link } from "react-router-dom";

const LatestNews = ({ page, types, cards }) => {
  const [isMobile, setIsMobile] = useState(
    window.document.documentElement.clientWidth <= 991
  );
  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const resizeHandler = () => {
    setIsMobile(window.document.documentElement.clientWidth <= 991);
  };

  return (
    <div className={page + " container-fluid"} id="latest-news">
      <div className={page + "-latest-news row"}>
        {isMobile ? (
          page === "whatsNew" ? (
            <LatestNewsMobileFilter
              page={"whatsNew"}
              types={types}
              cards={cards}
            />
          ) : (
            <LatestNewsMobile page={"home"} types={types} cards={cards} />
          )
        ) : (
          <>
            <div className="news-card-text">
              {page == "article" ? <p>Recommended News</p> : <p>Latest News</p>}

              {page !== "whatsNew" && page !== "article" ? (
                <Link to={"/whatsnew"}>
                  <h6 className="link-light">View All</h6>
                </Link>
              ) : (
                ""
              )}
            </div>
            {cards.map((card, index) => {
              return types === "all" || card?.["tags[]"]?.includes(types) ? (
                <div key={index} className="col-md-4 newsRow">
                  <Card
                    link={`/article/${card.slug}`}
                    pageType={page}
                    image={card.image}
                    title={card.title}
                    date={card.date}
                    text={card.text}
                    short_description={card.short_description}
                    media={card.media}
                    className={page === "home" ? "home-card" : ""}
                  />
                </div>
              ) : (
                ""
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};
export default LatestNews;
