import React, { useState, useEffect } from "react";
import "../Styles/Navbar.css";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const NavbarComponent = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.document.documentElement.clientWidth <= 991
  );

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const resizeHandler = () => {
    setIsMobile(window.document.documentElement.clientWidth <= 991);
    if (!isMobile) {
      setShowMenu(false);
    }
  };
  return (
    <div className="container-fluid" id="navbar-size">
      <nav
        className={
          showMenu
            ? "navbar navbar-expand-lg navbar-dark bg-black"
            : "navbar navbar-expand-lg navbar-dark bg-transparent"
        }
      >
        <a className="navbar-brand" href="home"></a>
        {isMobile ? (
          <button
            className="navbar-toggler"
            type="button"
            onClick={(event) => {
              setShowMenu(!showMenu);
            }}
          >
            {showMenu ? (
              <span
                type="button"
                className="btn-close btn-close-white"
                aria-label="Close"
              ></span>
            ) : (
              <span className="navbar-toggler-icon"></span>
            )}
          </button>
        ) : (
          <></>
        )}
        <div
          className={
            showMenu
              ? "navbar-collapse"
              : "collapse navbar-collapse bg-transparent"
          }
        >
          {isMobile && setShowMenu ? (
            <>
              <div className="menu-mobile" id="menuTitle">
                Menu
              </div>
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/whoweare">
                    Who We Are
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/whatsnew">
                    What's New
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">
                    Contact Us
                  </a>
                </li>
              </ul>
            </>
          ) : (
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activestyle="border-bottom: white 2px solid;"
                  aria-current="page"
                  to="/"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activestyle="border-bottom: white 2px solid;"
                  to="/whoweare"
                >
                  Who We Are
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activestyle="border-bottom: white 2px solid;"
                  to="/whatsnew"
                >
                  Whats New
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activestyle="border-bottom: white 2px solid;"
                  to="/contact"
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          )}
        </div>
      </nav>
    </div>
  );
};
export default NavbarComponent;
