import React from 'react'
import { Helmet } from 'react-helmet'
import '../../pages/Authentication/Login.css'


const Login = () => {
  return (
    <><Helmet>
      <title>Login | CMS | Swifty Global</title>
    </Helmet><section className="gradient-custom">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="card pt-0 bg-dark text-dark">
                <div className="card-body bg-light text-center">

                  <div className="mb-md-5 mt-md-4 pb-3">
                    <img src="../images/sp-logo-dark.png" className='swifty-logo' alt="Swifty Logo" />
                    <h4 className="welcome-text mb-2 mt-4">Welcome Back !</h4>
                    <div className="form-outline form-dark">
                      <label className="form-label justify-content-left" htmlFor="typeEmailX">Email</label>
                      <input type="email" id="typeEmailX" className="form-control form-control-md" />
                    </div>
                    <div className="form-outline form-dark">
                      <label className="form-label" htmlFor="typePasswordX">Password</label>
                      <input type="password" id="typePasswordX" className="form-control form-control-md" />
                    </div>
                    <div className='mb-3 row mt-4'>
                      <div className='col-6'>
                        <div className='form-check'>
                          <input type="checkbox" className='form-check-input'></input>
                          <label className='remember-me form-check-label'>Remember me</label>
                        </div>
                      </div>
                      <div className='col-6 text-end'>
                        <button className="btn btn-primary w-md waves-effect waves-light px-5" type="submit">Login</button>
                      </div>
                    </div>
                  </div>
                   <div className='form-group mb-0 row'>
                   <div className='forgot-password col-12 mt-4'>
                   <i class="bi bi-lock"></i>
                   <span src="../public/images/icons8-lock-24.png"></span>
                    <a className='text-muted' href='/forgot-password'>Forgot your password?</a>
                    </div>
                   </div>
                </div>
                </div>
              </div>
          </div>
          <div className='copyright mt-5 text-center'>
             <p>
                  Copyright © {new Date().getFullYear()} {" "}
                  <span className="d-none d-sm-inline-block">
                    Swifty Global
                  </span>
                </p>
          </div>
        </div>
      </section></>
  )
}

export default Login