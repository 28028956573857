const Button = (props) => {
  return (
    <button
      onClick={props.onclickHandler}
      className={props.className}
      {...props.attributes}
    >
      {props.children || props.text}
    </button>
  );
};

export default Button;
